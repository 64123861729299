import React from 'react';
import { ArrowRight, Search, Clock, Database, CheckCircle } from 'lucide-react';

import './App.css';

const Product = ({ title, description, features, ctaText, ctaLink }) => (
  <div className='product'>
    <h2>{title}</h2>
    <p>{description}</p>
    <ul>
      {features.map((feature, index) => (
        <li key={index}>
          <ArrowRight size={16} />
          {feature}
        </li>
      ))}
    </ul>
    <button
      onClick={() => window.open(ctaLink, '_blank')}
      className='cta-button'
    >
      {ctaText}
    </button>
  </div>
);

function App() {
  const handleJoinWaitlist = () => {
    window.open('https://nfactorialschool.typeform.com/to/BRGrpuZd', '_blank');
  };

  return (
    <div className='App'>
      <header>
        <h1>nFactorial AI</h1>
        <p className='subtitle'>
          Empowering Decision-Making with AI-Driven Insights
        </p>
      </header>

      <main>
        <section className='hero'>
          <h2>Unlock the Power of AI for Your Business</h2>
          <p>
            Harness cutting-edge AI technology to gain valuable insights and
            make informed decisions.
          </p>
          <button
            className='waitlist-button'
            onClick={handleJoinWaitlist}
          >
            Request Access
          </button>
        </section>

        <section className='products'>
          <Product
            title='YC S24 Investor Tool'
            description='Prepare for YC S24 with our comprehensive AI-powered analysis tool.'
            features={[
              '240+ parsed company profiles',
              'Quick answers to specific queries',
              "In-depth 'pro' answers for comprehensive insights",
              'Data from YC website, LinkedIn, company homepages, and more',
            ]}
            ctaText='Try YC S24 Tool'
            ctaLink='https://ycs24.nfactorial.dev'
          />
          <Product
            title='AI-Powered HR Agent'
            description='Revolutionize your hiring process with our intelligent HR assistant.'
            features={[
              'Analyze Excel spreadsheets and PDF files',
              'Browse and evaluate candidates efficiently',
              'Select the best candidates based on your criteria',
              'Answer complex HR-related questions',
            ]}
            ctaText='Explore HR Tool'
            ctaLink='https://hr.nfactorial.dev'
          />
        </section>

        <section className='features'>
          <div className='feature'>
            <Search size={48} />
            <h3>Intelligent Search</h3>
            <p>
              Quickly find relevant information with our advanced search
              capabilities.
            </p>
          </div>
          <div className='feature'>
            <Clock size={48} />
            <h3>Time-Saving</h3>
            <p>
              Get instant answers and save hours of manual research and
              analysis.
            </p>
          </div>
          <div className='feature'>
            <Database size={48} />
            <h3>Comprehensive Data</h3>
            <p>Access a wealth of information from various reliable sources.</p>
          </div>
          <div className='feature'>
            <CheckCircle size={48} />
            <h3>Accurate Insights</h3>
            <p>
              Benefit from AI-driven analysis for more informed decision-making.
            </p>
          </div>
        </section>
      </main>

      <footer className='footer'>
        Powered by <strong>nFactorial AI</strong>
      </footer>
    </div>
  );
}

export default App;
